import React from 'react';
import { useTranslation } from "react-i18next";


const NewsPreview = (props) => {
    const { t } = useTranslation();
    let id = props.id;
    let title = props.title;
    let date = props.date;
    let mediaType = props.mediaType;
    let mediaUrl = props.mediaUrl;
    
        if (mediaType == "image") {
        return <div className="newsPreviewBox">
        <div className="content">
            <div className="newsImageBox">
                <img src={mediaUrl} alt="" />
            </div>
            <div className="newsPreviewTitleBox">
                <div className="date">
                    <p className="date-text">{date}</p>
                </div>
                <h3>{title}</h3>
                <div className="newsPreviewTitle hidden">
                </div>
            </div>
        </div>
    </div>          
    } else if (mediaType == "video") {
        return <div className="newsPreviewBox">
        <div className="content">
            <div className="newsImageBox">
                <video className='videoTag' autoPlay loop muted>
                    <source src={mediaUrl} type='video/mp4' />
                </video>
            </div>
            <div className="newsPreviewTitleBox">
                <div className="date">
                    <p className="date-text">{date}</p>
                </div>
                <h3>{title}</h3>
                <div className="newsPreviewTitle hidden">
                </div>
            </div>
        </div>
    </div>
    }
    
     else return <div></div>
};

export default NewsPreview;
