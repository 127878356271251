import React from 'react';


import {useTranslation} from "react-i18next";


const NewsGalleryComponent = (props) => {
    const {t} = useTranslation();
    let id = props.id;
    let title = props.title;
    let date = props.date;
    let mediaType = props.mediaType;
    let mediaUrl = props.mediaUrl;
    if (mediaType == "image") {
        return <div className="newsGalleryBox">
            <div className="newsGalleryImageBox">
                <img src={mediaUrl} alt="" />
            </div>
            <div className="newsGalleryTitleBox">
                <p className="newsGalleryTitle">{title}</p>
            </div>
            <div className="newsDate">
                <p>{date}</p>
            </div>
        </div>
    } else if (mediaType == "video") {
        return <div className="newsGalleryBox">
            <video className='videoTag' autoPlay loop muted>
                <source src={mediaUrl} type='video/mp4' />
            </video>
            <div className="newsGalleryTitleBox">
                <p className="newsGalleryTitle">{title}</p>
            </div>
            <div className="newsDate">
                <p>{date}</p>
            </div>
        </div>
    } else return '';
};
export default NewsGalleryComponent;
